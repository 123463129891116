// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-content-template-js": () => import("/Users/bifrost/Repos/v2.liebi.com/src/templates/contentTemplate.js" /* webpackChunkName: "component---src-templates-content-template-js" */),
  "component---src-pages-404-js": () => import("/Users/bifrost/Repos/v2.liebi.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/Users/bifrost/Repos/v2.liebi.com/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/bifrost/Repos/v2.liebi.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-node-js": () => import("/Users/bifrost/Repos/v2.liebi.com/src/pages/node.js" /* webpackChunkName: "component---src-pages-node-js" */),
  "component---src-pages-study-js": () => import("/Users/bifrost/Repos/v2.liebi.com/src/pages/study.js" /* webpackChunkName: "component---src-pages-study-js" */)
}

